import { ChangeEvent, FC } from "react";
import "./style.scss";
import countries from "../../../assets/json/countries.json";
import {
  Box,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

interface props {
  className?: string;
  dialCodeName?: string;
  dialCodeValue: string;
  NumberFieldLabel?: string;
  onChange?: (
    e: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => void;
  disabled?: boolean;
  placeholder?: string;
  style?: any; // eslint-disable-line
  error: boolean;
  helperText: string | undefined;
  other?: any; // eslint-disable-line
  borderRadius?: string;
}

const MobileNumber: FC<props> = ({
  className,
  dialCodeName,
  dialCodeValue,
  NumberFieldLabel,
  onChange,
  style,
  disabled,
  placeholder,
  error,
  helperText,
  other,
  borderRadius,
}) => (
  <Box id="custom-mobile-number">
    <Box className={`custom-number ${className}`} style={style}>
      <Select
        labelId="demo-simple-select-label"
        id="flag-dropdown"
        name={dialCodeName}
        value={dialCodeValue}
        onChange={onChange}
        disabled={disabled}
        variant={disabled ? "standard" : "outlined"}
        size={disabled ? "medium" : "small"}
        error={error}
        sx={{ borderRadius: "4px 0px 0px 4px" }}
      >
           {
            countries.map((country, index) => <MenuItem key={index} value={`${(country.name).toUpperCase()}:${country.code}:${country.dial_code}`}>
                <Box display="flex" alignItems="center" >
                    <img className="flag-img mr-2" src={`/flags/${country.code.toLowerCase()}.svg`} />
                    {` ${country.dial_code}`}
                </Box>
            </MenuItem>)
        }
      </Select>
      <TextField
        className={borderRadius}
        {...other}
        id="number"
        type="number"
        label={NumberFieldLabel}
        disabled={disabled}
        placeholder={placeholder}
        variant={disabled ? "standard" : "outlined"}
        size={disabled ? "medium" : "small"}
        error={error}
        inputProps={{ onWheel: (e) => e.currentTarget.blur() }}
        // eslint-disable-next-line
        onKeyDown={(e: any) => {
          const symbol = ["backspace"];
          const exceptThisSymbols = ["e", "E", "+", "-", "."];
          if (
            (!symbol.includes(e.key.toLowerCase()) &&
              e.target.value.length > 14) ||
            exceptThisSymbols.includes(e.key.toLowerCase())
          ) {
            e.preventDefault();
          }
        }}
      />
    </Box>
    {error && (
      <FormHelperText
        sx={{ margin: "4px 4px 0px 14px", color: "#d32f2f" }}
        id="my-helper-text"
      >
        {helperText}
      </FormHelperText>
    )}
  </Box>
);
MobileNumber.defaultProps = {
  dialCodeName: "dialCode",
  NumberFieldLabel: "Number",
  disabled: false,
};

export default MobileNumber;
