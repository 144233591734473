import "./style.scss";
import { useState ,useEffect} from "react";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { Box } from "@mui/material";
import SideImage from "../../../src/assets/img/Group 43374.png";
import AddCandidate from "../../components/lead-form/AddCandidate";
import HeaderSection from "../../components/lead-form/HeaderSection";
import InfoSection from "../../components/lead-form/InfoSection";
import { useSearchParams } from "react-router-dom";
import { CandidateService } from "../../services/candidate";
import ContactUsPage from "../../components/ContactUsPage";
import ClientDetails from "../../components/lead-form/ClientDetails";
import YoutubeSection from "../../components/lead-form/YoutubeSection";
import ColabSection from "../../components/collaboration";
import EmailIcon from "@mui/icons-material/Email";
import JourneySection from "../../components/journey";
const LeadFrom = () => {
  const [firstRender,setFirstRender] = useState(false);
  const [embedIds, setEmbedIds] = useState<string[]>([]);
  const [searchParams] = useSearchParams();
  const cpdId = searchParams.get("cpdId");
  const { getCollegeDetails, getCareerHighlights } = CandidateService();
  let response: UseQueryResult<{data: {status: string}}> | null = null;

    const highlights = useQuery({
      queryKey: ["career-highlights"],
      queryFn: () => getCareerHighlights({}),
    });

    useEffect(() => {
      if (highlights?.data?.data) {
        const data = highlights.data.data;
        
        const embedIds = data
          .filter(item => item.youtubeUrl && item.youtubeUrl.startsWith("https://youtu.be"))
          .map(item => {
            const videoId = item.youtubeUrl.split("/")[3].split("?")[0];
            return videoId;
          });
    
        setEmbedIds(embedIds);
      }
    }, [highlights?.data?.data]);
    if (cpdId ) {
      response = useQuery({
        queryKey: ["cpdId"],
        queryFn: () => getCollegeDetails(cpdId),
      });

    } else {
      response = null;
    }
    useEffect(() => {
      if(response?.data?.data) {
        setFirstRender(true);
      }
    },[response?.data?.data]);

    const collegeStatus = response?.data?.data?.status;
    return (
    <>
      {
        !firstRender ? (<></>) : (
          <Box className="main-page">
            {collegeStatus === "CPD SCHEDULED" ? (
              <Box id="lead-form" sx={{ height: "100vh" }}>
                <HeaderSection />
                <Box className="college-form-box">
                  <Box className="form">
                  <Box className="form-box">
                    <Box className="colab-section">
                      <Box className="colab">
                        <ColabSection />
                      </Box>
                    </Box>
                    <InfoSection />
                    <Box className="info-box-wrapper">
                      <Box className="contact-image">
                          <Box className="left-img">
                            <img className="side-image" src={SideImage} alt="Side-Image" />
                          </Box>
                          <Box className="contact-mail">
                            <EmailIcon className="email-icon"/> <p>contact@relinns.com</p>
                          </Box>
                          <Box className="trusted-clients">
                            Our Trusted Clients
                          </Box>
                          <Box className="clients">
                            <ClientDetails />
                          </Box>
                      </Box>
                      <Box className="application-form-box">
                        <AddCandidate />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <JourneySection />
                  </Box>
                  <Box className="youtube-section">
                    <YoutubeSection embedIds={embedIds} />
                  </Box>
                  </Box>
                </Box>
            </Box>
            ) : (
              <Box>
                <ContactUsPage />
              </Box>
            )}
          </Box>
        )
      }
    </>
  );
};

export default LeadFrom;
