import { Typography, Box, Link } from "@mui/material";
import ConfettiExplosion from "react-confetti-explosion";
import ThankYouLogo from "../../../assets/img/Thank-you3.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import GroupPhoto from "../../../assets/img/Christmas2.jpg";
import "./style.scss";

const ThankYouDetails = () => (
  <Box className="thankyou-main">
    <Box>
      <ConfettiExplosion />
    </Box>
    <Box className="thankyou-head">
      <Typography className="thankyou-message">Thank You!</Typography>
      <Typography className="message">
        We are excited to work with you.
      </Typography>
      <Typography className="message">
        Best of luck for your interview round with Relinns!
      </Typography>
      <Typography className="message">
        Our HR team will directly inform the college authorities about your
        interview status.
      </Typography>
      <img src={ThankYouLogo} />
      <Box className="thankyou-icons">
        <Link href="https://www.linkedin.com/company/relinns-technologies/mycompany/">
          <LinkedInIcon className="linkedin-icon" />
        </Link>
        <Link href="https://www.youtube.com/c/RelinnsTechnologies/videos">
          <YouTubeIcon className="youtube-icon" />
        </Link>
        <Link href="https://www.facebook.com/relinnstechnologies/">
          <FacebookIcon className="facebook-icon" />
        </Link>
        <Link href="https://www.instagram.com/relinns.technologies/">
          <InstagramIcon className="instagram-icon" />
        </Link>
      </Box>
    </Box>
    <Box className="thankyou-footer">
      <img src={GroupPhoto} alt="Group-Photo" className="footer-image" />
    </Box>
  </Box>
);

export default ThankYouDetails;
