import "./style.scss";
import { Box } from "@mui/material";
import CompanyLogo from "../../assets/img/Image 1.png";
import { Typography } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "@mui/material";

const ContactUsPage = () => (
  <Box className="contact-page">
    <Box className="main">
      <Box className="first-para">
        <Box className="info">
          <Typography className="info-heading">Get in Touch</Typography>
          <Typography>
            Our Drive is closed for visits, but you can explore our career
            opportunities on our website.
          </Typography>
        </Box>
        <img src={CompanyLogo} alt="relinns-logo"/>
      </Box>
      <Box className="info-icon">
        <Typography className="info-icon-header">
          Find Relinns Tecnology elsewhere
        </Typography>
        <Box className="icon">
          <Box className="icon1">
            <Link
              href="https://www.linkedin.com/company/relinns-technologies/mycompany/"
              target="_blank"
            >
              <LinkedInIcon className="linkedin" />
            </Link>
            <Link
              href="https://www.youtube.com/c/RelinnsTechnologies/videos"
              target="_blank"
            >
              <YouTubeIcon className="youtube" />
            </Link>
          </Box>
          <Box className="icon2">
            <Link
              href="https://www.facebook.com/relinnstechnologies/"
              target="_blank"
            >
              <FacebookIcon className="facebook" />
            </Link>
            <Link
              href="https://www.instagram.com/relinns.technologies/"
              target="_blank"
            >
              <InstagramIcon className="instagram" />
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
);

export default ContactUsPage;
