import "./style.scss";
import React, { useState } from "react";
import NextArrow from "../../../assets/img/Group 43359.svg";
import PrevArrow from "../../../assets/img/Group 43360.svg";
interface YoutubeEmbedProps {
  embedIds: string[];
}

const YoutubeEmbed: React.FC<YoutubeEmbedProps> = ({ embedIds }) => {

  const [currentIdIndex, setCurrentIdIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIdIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : embedIds.length - 1));
  };

  const goToNext = () => {
    setCurrentIdIndex((prevIndex) => (prevIndex < embedIds.length - 1 ? prevIndex + 1 : 0));
  };

  const embedId = embedIds[currentIdIndex];
  return (
  <div className="vid-container">
    <div className="video-responsive">
      <iframe
        className="frame-container"
        src={`https://www.youtube.com/embed/${embedId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
       <div className="navigation-buttons">
        <button className="prev" onClick={goToPrevious}><img src={PrevArrow}/></button>
        <button className="next" onClick={goToNext}><img src={NextArrow}/></button>
    </div>
    </div>
   
  </div>);
};

export default YoutubeEmbed;
