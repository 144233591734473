import HttpService from "../http";
import { ICandidatesResponse } from "../../Interface/Candidate";
import { careerHighlights, collegeName, create, list } from "../endpoints";
import { IApiResponse } from "../../Interface/job";
import { ITData } from "../../Interface/College";
import { ICareerHighlightsData } from "../../Interface/career-highlights";

const CandidateService = () => {
    const { httpRequest } = HttpService();
    const addCandidate = async (payload: object): Promise<ICandidatesResponse> => new Promise((resolve, reject) => {
        httpRequest<ICandidatesResponse>(
            "POST",
            `${create}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getJobTitle = async (search?: { type: string, status: string }): Promise<IApiResponse> => new Promise((resolve, reject) => {
        httpRequest<IApiResponse>(
            "GET",
            `${list}?type=${search?.type}&status=${search?.status}`,
            search

        )
            .then(resolve)
            .catch(reject);
    });
    const getCollegeDetails = async (cpdId: string): Promise<ITData> => new Promise((resolve, reject) => {
        httpRequest<ITData>(
            "GET",
            `${collegeName}`,
            {},
            { cpdId }
        )
            .then(resolve)
            .catch(reject);
    });

    const getCareerHighlights = async (search = {}): Promise<ICareerHighlightsData> => new Promise((resolve, reject) => {
        httpRequest<ICareerHighlightsData>(
            "GET",
            `${careerHighlights}`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });
    return { addCandidate, getJobTitle, getCollegeDetails,getCareerHighlights };
};
export { CandidateService };