import "./style.scss";
import { Box, Typography } from "@mui/material";

const JourneySection = () => (
  <Box>
    <Box className="journey-box-header">
      <Typography className="journey-box-header-heading">Intern To Full-time Journeys</Typography>
      <Typography className="journey-box-header-subheading-first">
        The evolution from intern to full-time team member
      </Typography>
    </Box>
  </Box>
);

export default JourneySection;
