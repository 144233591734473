import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import routes from "./routes";
import theme from "./assets/theme";
import "./App.scss";
import "./assets/css/bootstrap/style.scss";
import { SnackbarProvider } from "notistack";
const App = () =>
(
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
      >

        <RouterProvider router={routes} />
      </SnackbarProvider>
    </ThemeProvider>
);


export default App;
