import "./style.scss";
import { Box, Typography } from "@mui/material";

const InfoSection = () => (
  <Box>
    <Box className="form-box-header">
      <Typography className="form-box-header-heading">Registration Form</Typography>
      <Typography className="form-box-header-subheading-first">
        Employment Opportunity
      </Typography>
    </Box>
  </Box>
);

export default InfoSection;
