import { createBrowserRouter } from "react-router-dom";
import PageNotFound from "../components/page-not-found";

import LeadFrom from "../screens/lead-form";
import ThankYouDetails from "../components/lead-form/ThankYouDetails";

export default createBrowserRouter([
    {
        path: "",
        element: <LeadFrom />,
        errorElement: <PageNotFound />
    },
    {
        path: "submitted",
        element: <ThankYouDetails />
    }
]);  
