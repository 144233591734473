import "./style.scss";
import clientData from "../../../assets/json/clientData.json";
const ClientDetails = () => (
  <div className="container mt-5" >
    <div className="icons">
      <div className="icons-data">
        {clientData.map((client, index) => (
          <div key={index}>
            <img
              alt={client.alt}
              src={client.src}
              loading="lazy"
              className="client-logo"
            />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default ClientDetails;
