import Joi from "joi";
export const AddCandidateValidation = Joi.object({
    name: Joi.string()
        .regex(/^[a-zA-Z\s]+$/)
        .trim()
        .min(3)
        .max(40)
        .required()
        .custom((value, helpers) => {
            if (value.trim() === "") {
                return helpers.error("string.empty");
            }
            return value;
        }, "Name Validation")
        .label("Name"),
    email: Joi.string()
        .trim()
        .email({ tlds: { allow: false } })
        .lowercase()
        .label("Email"),
    mobileNumber: Joi.string()
        .trim()
        .required()
        .length(10)
        .label("Mobile Number"),
    degree: Joi.string()
        .trim()
        .required()
        .label("Degree"),
    graduationYear: Joi.string()
        .required()
        .label("Graduation Year"),
    jobRole: Joi.string()
        .required()
        .label("Job Role"),
    jobId: Joi.string()
        .required()
        .label("Job Title"),
    resumes: Joi.string()
        .required()
        .label("Resume")
});