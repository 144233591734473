import { Backdrop,Box } from "@mui/material";
import Lottie from "lottie-react";
import LoaderIcon from "../../assets/lottie/loader.json";
import { FC,ReactNode,createContext,useState } from "react";
import { TLoaderContext } from "../../Interface/loader";


const LoaderContext = createContext<TLoaderContext | unknown>({});

interface ContextProps {
    children : ReactNode;
}

const LoadingProvider: FC<ContextProps> = ({children}) => 
{
    const [isLoading,setIsLoading] = useState<boolean>
    (false);
    return (
        <LoaderContext.Provider value = {{isLoading,setIsLoading }}>
            <Loader isLoading={isLoading}/>
            {children}
        </LoaderContext.Provider>
    );
};


interface loaderProps {
    isLoading : boolean;
}


const Loader : FC<loaderProps> = ({isLoading}) => {
    if(isLoading){
        return <Backdrop
        sx={{zIndex:(theme) => theme.zIndex.drawer + 10000}}
        open={isLoading}
        >
            <Box width={200} height={200}>
                <Lottie
                animationData={LoaderIcon}
                width="100%"
                height="100%"
            />
            </Box>
        </Backdrop>;
    } else {
        return <></>;
    }
};
export {LoadingProvider,LoaderContext};
export default Loader;